.radio svg {
    width: 16px;
    height: 16px;
    min-height: 16px;
    min-width: 16px;
    margin-right: 8px;
    cursor: pointer;
}

.radio label {
    -webkit-tap-highlight-color: transparent !important;
    -webkit-touch-callout: none !important;
    -webkit-user-select: none !important;
    -khtml-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
    cursor: pointer;
}

.disabled {
    cursor: not-allowed;
}

.disabled label {
    cursor: not-allowed;
}
