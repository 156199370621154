#root {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-tap-highlight-color: transparent;
  position: relative;
  overflow: hidden;
  scroll-behavior: unset;
}

body {
  margin: 0;
  padding: 0;
  -webkit-tap-highlight-color: transparent;
  position: relative;
  overflow: hidden;
}

html {
  background-color: white;
}

button {
  -webkit-tap-highlight-color: transparent;
}

.map-container {
  height: 100%;
  width: 100%;
}
