.h1-variant {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  margin: 0;
  padding: 0;
  margin-block: 0;
  margin-inline: 0;
}

.h2-variant {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  margin: 0;
  padding: 0;
  margin-block: 0;
  margin-inline: 0;
}

.h3-variant {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  margin: 0;
  padding: 0;
  margin-block: 0;
  margin-inline: 0;
}

.p-variant {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  padding: 0;
  margin-block: 0;
  margin-inline: 0;
}

.body-variant {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  padding: 0;
  margin-block: 0;
  margin-inline: 0;
}

.p-small-variant {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  padding: 0;
  margin-block: 0;
  margin-inline: 0;
}

.small-variant {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  margin: 0;
  padding: 0;
  margin-block: 0;
  margin-inline: 0;
}

.x-small-variant {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin: 0;
  padding: 0;
  margin-block: 0;
  margin-inline: 0;
}

.tiny-variant {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  margin: 0;
  padding: 0;
  margin-block: 0;
  margin-inline: 0;
}

.bold {
  font-weight: 700;
}

.center {
  text-align: center;
}

.uppercase {
  text-transform: uppercase;
}

.tabular {
  font-feature-settings: 'tnum' on, 'lnum' on;
}

.fullWidth {
  width: 100%;
}

.underline {
  text-decoration: underline;
}

.isClickable {
  cursor: pointer;
}

.lineThrough {
  text-decoration: line-through;
}

.no-user-select {
  -webkit-tap-highlight-color: transparent !important;
  -webkit-touch-callout: none !important;
  -webkit-user-select: none !important;
  -khtml-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
